import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import Logo from "../assets/images/logo2.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <Box mt="80px" bgcolor="#fff3f4">
      <Stack gap="10px" alignItems="center" px="40px" pt="24px">
        <img src={Logo} alt="logo" width="50px" height="30px" />
        <Typography
          variant="h5"
          pb="40px"
          mt="5px"
          sx={{
            fontSize: { lg: "15px", xs: "10px" },
          }}
        >
          Made By
          <a href="https://www.rahmou.com/" target="_blank" rel="noreferrer">
            {" "}
            Rahmou
          </a>
          , All reserved rights {year}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Footer;
